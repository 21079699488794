import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
export default function RouteWithSubRoutes(route:RouteConfig) {
    const render = (subRoute:RouteConfig)=>{
        return (<RouteWithSubRoutes {...subRoute} />)
    }
    const renderRedirect = (fullpath)=>{ if (fullpath == route.path) {
        // @ts-ignore
        return (<Redirect to={route.redirect!}/>)
    }}
    return (
        // @ts-ignore
        <Route
            path={route.path}
            render={props => {
                console.log(props)
                console.log(route)
                const fullpath = props.location.pathname
                if (route.children && route.children.length > 0) {
                    return (
                        <>{/*
 // @ts-ignore*/}
                            <Switch>
                                {route.children.map(render)}
                            </Switch>
                            {renderRedirect(fullpath)}
                        </>
                    )
                } else if (route.component) {
                    const Component = loadable(route.component)
                    return (<Component />)
                }
            }}
        />
    );
}

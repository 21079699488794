import store from "../app/store";
import {login,logout} from "../app/loginSlice";
import * as XLSX from "xlsx";
import moment from "moment/moment";
import { sha256 } from 'js-sha256';
const requestBase: RequestInit = {
    headers: {'Content-Type': 'application/json'}
}
const baseRequestMiddleWare: RequestMiddleWare<any> = async (req,next) => {
    const {url, param, data, body, scope,...options} = req
    let urlAssembled = url
    if (param) {
        urlAssembled = urlAssembled + '?' + Object.entries(param).map(([key,val]) => key+'='+encodeURIComponent(''+val)).join('&')
    }
    let requestAssembled:RequestInit = mergeDeep({},requestBase,options)
    if (body) {
        requestAssembled.body = body
    } else if (data) {
        requestAssembled.body = JSON.stringify(data)
    }
    if (scope) {
        requestAssembled.signal = scope.handler?.signal
    }
    const reqObj = new Request(urlAssembled, requestAssembled)
    const response = await fetch(reqObj) as ResponseCtx<any>
    if (response.ok) {
        let res = await response.json(); // parses JSON response into native JavaScript objects
        response.parsedPayload = res
        return response
    } else if (response.status == 401) {
        store.dispatch(logout())
    }
    return response
}

export async function request2(req: RequestCtx,middlewares: RequestMiddleWare<any>[] = []): Promise<ResponseCtx<any>> {
    middlewares.push(baseRequestMiddleWare)
    let arr = middlewares.reverse()
    let pre: RequestHandler<any> | undefined = undefined
    let cur: RequestHandler<any> | undefined = undefined
    for (const middleware of arr) {
        const pre_ = pre
        cur = async (lcreq) => await middleware(lcreq,pre_)
        pre = cur
    }
    return await pre!(req);
}
interface RequestMiddleWareWrapper<T> {
    currentMiddleware:RequestMiddleWare<T>
    nextMiddlewareWrapper:RequestMiddleWareWrapper<T>|undefined
}
const applyRequestMiddleWare = async <T>(req: RequestCtx,wrapper:RequestMiddleWareWrapper<T>|undefined):Promise<ResponseCtx<T>> => {
    if (wrapper) {
        const {currentMiddleware,nextMiddlewareWrapper} = wrapper
        return await currentMiddleware(req,async (req) => await applyRequestMiddleWare(req,nextMiddlewareWrapper))
    } else {
        throw new Error('no middleware')
    }
}
export async function request3(req: RequestCtx,middlewares: RequestMiddleWare<any>[] = []): Promise<ResponseCtx<any>> {
    middlewares.push(baseRequestMiddleWare)
    let firstWrapper:RequestMiddleWareWrapper<any>|undefined = undefined
    let preWrapper:RequestMiddleWareWrapper<any>|undefined = undefined
    for (const middleware of middlewares) {
        const wrapper:RequestMiddleWareWrapper<any> = {
            currentMiddleware:middleware,
            nextMiddlewareWrapper:undefined
        }
        if (preWrapper) {
            preWrapper.nextMiddlewareWrapper = wrapper
        }
        if (!firstWrapper) {
            firstWrapper = wrapper
        }
        preWrapper = wrapper
    }
    return await applyRequestMiddleWare(req,firstWrapper);
}
export function numGetDigits(num: number) {
    const ans:number[] = [];
    while (num > 0) {
        ans.push(num % 10);
        num = Math.floor(num / 10)
    }
    return ans;
}
export function stringReplaceAll(str, target, replace) {
    while (str && str.indexOf(target) !== -1) {
        str = str.replace(target, replace)
    }
    return str;
}
export function utf8str2ab(str:string,pad:number[]=[]){
    const b:number[] = [];
    for (let i=0, strLen=str.length; i < strLen; i++) {
        let c = str.charCodeAt(i);
        if (c < 128) {b.push(c)}
        else if(c < 2048) { b.push(c >> 6 | 192); b.push(c & 63 | 128);}
        else {b.push(c >> 12 | 224); b.push(c >> 6 & 63 | 128); b.push(c & 63 | 128);};
    }
    if (pad.length > 0) b.push(...pad);
    const buf = new ArrayBuffer(b.length);
    const bufview = new Uint8Array(buf);
    b.forEach((item,index)=>{
        bufview[index] = item;
    });
    return buf
}
export function ab2utf8str(ab:ArrayBuffer) {
    let array = new Uint8Array(ab);
    return uint8arr2utf8str(array);
}
export function uint8arr2utf8str(array:Uint8Array){
    let out:string[] = [], i=0, len = array.length, c:number;
    let char2:number, char3:number;
    while(i < len) {
        c = array[i++];
        switch(c >> 4)
        {
            case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
            // 0xxxxxxx
            out.push(String.fromCharCode(c));
            break;
            case 12: case 13:
            // 110x xxxx   10xx xxxx
            char2 = array[i++];
            out.push(String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F)));
            break;
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = array[i++];
                char3 = array[i++];
                out.push(String.fromCharCode(((c & 0x0F) << 12) |
                    ((char2 & 0x3F) << 6) |
                    ((char3 & 0x3F) << 0)));
                break;
        }
    }

    return out.join('');
}
export function isLineEmpty(arr:string[]){
    let ans = true;
    for (let txt of arr) {
        if (txt) {
            txt = txt.trim()
            if (txt) {
                ans = false;
                return ans;
            }
        }
    }
    return ans;
}
export async function getCsvWriteHandle(name:string){
    return (window as any).showSaveFilePicker({suggestedName:name+'.csv',types:[{description:'csv',accept:{'text/csv':['.csv']}}]})
}
export function* processCsv(ab:ArrayBuffer,headerLines = 1,batch = 100) {
    const byteBatch = 1024; // 1kb
    const len = ab.byteLength;
    let idx = 0;
    let headerBuf:any[][] = [];
    let lineBuf:any[][] = [];
    let buf:Uint8Array|null = null;
    let isWin = isWindows();
    //isWin = false;
    let decoder:TextDecoder|undefined;
    if (isWin) {
        decoder = new TextDecoder('gbk')
    }
    while (idx < len) {
        let uidx = 0,lineStart = 0;
        let bufEnd = Math.min(idx+byteBatch,len)
        buf = new Uint8Array(ab.slice(idx,bufEnd))

        while (uidx < buf.length) {
            const ch = buf[uidx];
            if (ch === 0x0A) {
                // 0x0D /r
                // 0x0A /n
                let lineEnd = uidx;
                let tmpNextStart = uidx+1;
                if (buf[uidx-1] === 0x0D) {
                    lineEnd--;
                }
                let lineStr = ''
                if (isWin) {
                    lineStr = decoder!.decode(buf.subarray(lineStart, lineEnd))
                } else {
                    lineStr = uint8arr2utf8str(buf.subarray(lineStart, lineEnd));
                }
                lineStart = tmpNextStart;
                let lineArr = lineStr.split(',');
                if (!isLineEmpty(lineArr)) {
                    if (headerLines > 0) {
                        console.log(lineArr)
                        headerLines--;
                        headerBuf.push(lineArr)
                        if (headerLines === 0) yield headerBuf
                    } else {
                        lineBuf.push(lineArr);
                        if (lineBuf.length >= batch) {
                            yield lineBuf;
                            lineBuf = []
                        }
                    }
                }
            }
            uidx++;
        }
        if (bufEnd === len) break;
        idx+= lineStart;
    }
    if (lineBuf.length > 0) {
        yield lineBuf;
        lineBuf = []
    }
}
export async function readSingleFile(file:File){
    const reader = new FileReader();
    return new Promise<ArrayBuffer>((resolve,reject)=>{
        reader.onload = (e) => {
            /* Parse data */
            const ab = e.target!.result as ArrayBuffer;
            resolve(ab)
        };
        reader.onerror = (e) => {
            console.log(e)
            reject(e)
        }
        reader.readAsArrayBuffer(file);
    })
}
export function provinceCompare(a, b) {
    if (a.label.indexOf("全") != -1) {
        return -1;
    }
    if (b.label.indexOf("全") != -1) {
        return 1;
    }
    return a.label.localeCompare(b.label);
}

export function getParamValue(queryParam, userParam, key) {
    if (userParam[key]) {
        const val = userParam[key];
        if (Array.isArray(val)) {
            let val2set = val[0];
            if (queryParam[key]) {
                const target = queryParam[key];
                val.forEach((tmp) => {
                    if (tmp == target) {
                        val2set = tmp;
                    }
                })
            }
            return val2set;
        } else {
            return val;
        }
    } else {
        return queryParam[key];
    }
}
export function arrayChunk(arr:any[] = [], size = 1) {
    const ans:any[] = [];
    let buf:any[] = [];
    for (const obj of arr) {
        buf.push(obj)
        if (buf.length == size) {
            ans.push(buf)
            buf = []
        }
    }
    if (buf.length > 0) {
        ans.push(buf)
    }
    return ans;
}
interface dvconfig {
    ts: number
    pts: number
    rid: number
    type: number
    config: {}
    list: []
}
export function getCurrentTime() {
    return (new Date()).getTime()
}
export function isServer() {
    return typeof window === 'undefined';
}
export function getCurrentYear() {
    return (new Date()).getFullYear()
}
export function lintObject(obj: Object) {
    for (const [key, val] of Object.entries(obj)) {
        if (!val) {
            delete obj[key];
        }
    }
}
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
export function stringFormat(str, ...params) {
    let res = str;
    let i = 1;
    for (const param of params) {
        res = res.replace(`{${i}}`, param)
        i++;
    }
    return res;
}
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function deepClone(obj:any) {
    if (isObject(obj)) {
        const objcp = {};
        for (const [key, val] of Object.entries(obj)) {
            objcp[key] = deepClone(val);
        }
        return objcp;
    } else if (Array.isArray(obj)) {
        const arr:any[] = [];
        for (const item of obj) {
            arr.push(deepClone(item))
        }
        return arr;
    } else {
        return obj;
    }
}

export function transformConfig(obj: dvconfig = { ts: 0, pts: 0, rid: 0, type: 0, config: {}, list: [] }, ...callbacks) {
    if (!obj) return;
    if (obj.config) {
        transformObj(obj.config, ...callbacks)
    }
    obj.list.forEach((subconfig) => { transformConfig(subconfig, ...callbacks) })
}
export function transformObj(obj: object, ...callback) {
    if (typeof obj !== 'object' || obj == null || obj == undefined) {
        return;
    }
    for (const [key, val] of Object.entries(obj)) {
        let neoVal = val
        if (typeof neoVal === 'object' && !Array.isArray(neoVal) && neoVal && !neoVal.transform) {
            transformObj(neoVal, ...callback)
        } else if (Array.isArray(neoVal) && ['geometryOptions'].includes(key)) {
            neoVal.forEach((subobj) => {
                transformObj(subobj, ...callback)
            })
        } else {
            for (const fn of callback) {
                neoVal = fn(key, neoVal)
            }
            obj[key] = neoVal
        }
    }
}
export function incrementTs(obj: dvconfig = { ts: 0, pts: 0, rid: 0, type: 0, config: {}, list: [] }) {
    if (!obj) return;
    obj.ts += 1;
    obj.list.forEach((subconfig) => { incrementTs(subconfig) })
}
export function checkAllZero(data: any[], log = false) {

    let allZero = true;
    if (!Array.isArray(data)) return allZero;
    for (const obj of data) {
        if (!checkNumZero(obj, log) && obj.length !== 0) {
            allZero = false;
            break;
        } else {
            continue;
        }

    }
    return allZero;
}
export function round2(num: number) {
    return Math.round(num * 100) / 100;
}
export function rpad2zero(num: number) {
    let str = '' + num;
    const arr = str.split('.');
    if (arr.length == 2) {
        str = arr[0] + '.' + arr[1].padEnd(2, '0');
    } else if (arr.length == 1) {
        str = str + '.00'
    }
    return str;
}
export function removeDup(arr: any[], key: string) {
    const hash = {}
    for (const obj of arr) {
        hash[obj[key]] = obj
    }
    return Object.values(hash)
}
export function standardError(inputs: number[][], outputs: number[][]) {
    const n = inputs.length;
    let xmean = 0;
    for (let i = 0; i < n; i++) {
        xmean += inputs[i][0];
    }
    xmean /= n;
    let yvar = 0, xvar = 0;
    for (let i = 0; i < n; i++) {
        yvar += Math.pow(outputs[i][1] - inputs[i][1], 2);
        xvar += Math.pow(inputs[i][0] - xmean, 2);
    }
    return Math.sqrt(yvar / (n - 2)) / Math.sqrt(xvar);
}
export function checkNumZero(obj: {}, log = false) {
    let allZero = true;
    let numChecked = 0;
    let str = '';
    for (let [key, valRaw] of Object.entries(obj)) {

        if (log) {
            //console.log('hahahaha')
            //console.log(str)
        }
        str = '' + valRaw;
        let valany = valRaw as any;
        if (isNaN(valany)) {
            continue;
        }
        let val = '' + valany;
        const unitArrs = ['%', '万元']
        unitArrs.forEach((unit) => {
            val = val.replace(unit, '')
        })
        str += '' + val
        str += ':'


        let num = Number.parseFloat(val as string);
        str += '' + num;
        str += ':'
        if (!isNaN(num)) {
            numChecked = numChecked + 1;
            if (num != 0) {
                allZero = false;
                break;
            } else {
                continue;
            }
        }

        num = Number.parseInt(val as string);
        str += num;
        str += ':'
        if (!isNaN(num)) {
            numChecked = numChecked + 1;
            if (num != 0) {
                allZero = false;
                break;
            } else {
                continue;
            }
        }

    }
    if (allZero && numChecked || log) {
        console.log(obj)
        console.log(allZero)
        console.log(numChecked)
    }
    return allZero && numChecked > 0;
}

export function sortObjectKeysByMap(obj:any,map:Record<string,string>):any{//
    let neoObj = {};
    if (!obj) return neoObj;
    for (const key of Object.keys(map)) {
        if (obj.hasOwnProperty(key)) {
            neoObj[key] = obj[key];
        }
    }
    return neoObj
}

export function checkAllZeroArr(arr:any[],str:string){
    let allZero = true;
    for (const obj of arr) {
        if (obj[str] != 0)  return false;
    }
    return allZero;
}
export function isWindows(){
    if (navigator) {
        return navigator.userAgent.indexOf("Win") != -1
    }
}
export function stringRegexRemove(str:string,reg:RegExp){
    if (str.replaceAll) {
        return str.replaceAll(reg,'');
    } else {
        let sb:string[] = []
        let len = str.length;
        for (let i=0;i<len;i++) {
            const ch = str.charAt(i);
            if (ch.match(reg)) {
                continue;
            } else {
                sb.push(ch)
            }
        }
        return sb.join('')
    }
}
export function runParallel(tasks:any[] | Generator<any[]>,handle:any[],batch:number = 200,threads:number = 5,retry = 1,failed = false){
    let finishLeft = threads;
    let successNum = 0;
    let failNum = 0;
    let failedArr:any[] = [];
    const worker = async (buf:any[],id:number)=>{
        let isOver = false;
        let ans = false;
        console.log({buf,id})
        if (Array.isArray(tasks)) {
            const pl = tasks.pop()
            if (pl == undefined) {
                ans = true;
            } else {
                buf.push(pl);
            }
        } else {
            const {value,done} = (tasks as Generator).next()

            if (done) {
                ans = true;
            } else {
                buf.push(...value as any[][])
            }
        }
        if (buf.length >= batch) {
            let tries = 0;
            while (tries<=retry) {
                try {
                    const res:MessageVO = await handle[0](handle[1]([...buf]));
                    if (res.status == 0) {
                        successNum+=buf.length
                        break;
                    }
                } catch (e) {
                    console.log(e)
                }
                tries++
            }
            if (tries > retry) {
                failNum+=buf.length
                if (failed) {
                    failedArr.push(...buf)
                }
            }
            buf.length = 0
        }
        if (!ans) return false;
        if (buf.length > 0) {
            let tries = 0;
            while (tries++<=retry) {
                try {
                    const res:MessageVO = await handle[0](handle[1](buf));
                    if (res.status == 0) {
                        successNum+=buf.length
                        break;
                    }
                } catch (e) {
                    console.log(e)
                }
            }
            if (tries > retry) {
                failNum+=buf.length
                if (failed) {
                    failedArr.push(...buf)
                }
            }
        }
        if (--finishLeft == 0) {
            handle[2](successNum,failNum,failedArr)
        }
        return true
    }
    let id = 0;
    while (threads-->0) {
        const buf:any[] = []
        setTimeout(workerWrapper(worker,[buf,++id]),0);
    }
}

export function exportExcel(data:any[][],sheetName:string,filePrefix:string){
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, filePrefix+'-'+moment().format('YYYY-MM-DD-hh:mm:ss')+".xlsx")
}
export function callParallel<T>(tasks:any[],handle:Function,cb:Function,finishCb:Function,threads:number = 5,retry = 1,threshold = 10000){
    let left = tasks.length;
    let resArr:T[] = []
    const worker = async ()=>{
        const pl = tasks.pop()
        if (pl == undefined) {
            return true;
        } else {
            let tries = 0;
            while (tries++ <= retry) {
                try {
                    const res: SimpleListVO<T> = await handle(...pl);
                    if (res.status == 0) {
                        resArr.push(...res.list)
                        if (resArr.length > threshold) {
                            const cbPl = resArr
                            resArr = []
                            cb(cbPl);
                        }
                        break;
                    }
                    ;
                } catch (e) {
                    console.log(e)
                }
            }
            const leftVal = --left;
            if (leftVal == 0) {
                if (resArr.length > 0) {
                    cb(resArr);
                }
                finishCb(resArr)
            }
        }
        return false;
    }
    while (threads-->0) {
        setTimeout(workerWrapper(worker),0);
    }
}

interface WorkerContext {
    running: boolean,
    handle?: NodeJS.Timeout
}

type Node<T> = {
    prev?:Node<T>,
    value?:T,
    next?:Node<T>,
}
type Queue<T> = {
    head?:Node<T>,
    tail?:Node<T>,
    size:number
}
type AsyncFunction = ()=>Promise<unknown>
const makeQueue = <T>():Queue<T>=>{
    const head:Node<T> = {},tail:Node<T> = {}
    head.next = tail;
    tail.prev = head;
    return {head,tail,size:0}
}
const makeNode = <T>(pl:T):Node<T>=>{

    return {value:pl}
}
const destroyQueue = (queue:Queue<unknown>) => {
    destroyNode(queue.head)
    queue.head = undefined
    queue.tail = undefined
    queue.size = 0
}
const destroyNode = (node:Node<unknown>|undefined) => {
    if (node != undefined) {
        node.value = undefined
        destroyNode(node.next)
        node.next = undefined
    }
}
const enqueue = <T>(pl:T,queue:Queue<T>) => {
    queue.size++
    const node = makeNode(pl)
    const head = queue.head!
    const next = head.next!
    head.next = node;
    node.prev = head;
    node.next = next;
    next.prev = node;
}

const dequeue = <T>(queue:Queue<T>):T|undefined => {
    if (queue.size == 0) return undefined
    const tail = queue.tail!
    const node = tail.prev!
    const prev = node.prev!
    prev.next = tail
    tail.prev = prev
    node.next = undefined
    node.prev = undefined
    return node.value
}
const looper = async (context:WorkerContext,queue:Queue<AsyncFunction>) => {
    if (context.running) {
        const task = dequeue(queue)
        if (task != undefined) {
            try {
                await task!()
            } catch {
            }
            context.handle = setTimeout(()=>{
                looper(context,queue)
            },0)
        } else {
            context.running = false
            context.handle = undefined
        }
    }
}
const wakeWorker = (context:WorkerContext,queue:Queue<AsyncFunction>) => {
    if (context.running) return
    context.running = true
    looper(context,queue)
}
const stopWorker = (context:WorkerContext) => {
    context.running = false
    if (context.handle != undefined) {
        clearTimeout(context.handle)
        context.handle = undefined
    }
}
export function parallel2(limit:number,retry = 0){
    const workers:WorkerContext[] = []
    for (let i=0;i<limit;i++) {
        workers.push({running:false})
    }
    const queue = makeQueue<AsyncFunction>()
    const run = async function (fn,resolve,reject) {
        let left = retry + 1;
        while (left-->0) {
            try {
                const res = await fn()
                resolve(res)
            } catch (e) {
                if (left == 0) {
                    reject(e)
                }
            }
        }
    }
    const wakeAll = () => {
        workers.map(worker=>wakeWorker(worker,queue))
    }
    const gen = (fn:()=>Promise<unknown>)=>new Promise((resolve,reject)=>{
        const pl = run.bind(null,fn,resolve,reject)
        enqueue(pl,queue)
        wakeAll()
    })
    Object.defineProperties(gen,{
        pendingCount: {
            get: () => queue.size,
        },
        clearQueue: {
            value: () => {
                workers.map(w=>stopWorker(w))
                destroyQueue(queue)
            },
        },
    })
}
export function workerWrapper(worker:any,initParam:any[] = []){
    return async ()=> {
        const res = await worker(...initParam);
        if (!res) {
            const nextFunc = workerWrapper(worker,initParam)
            setTimeout(nextFunc, 0);
        }
    }
}

export function genericTypeCompare<I,U>(itype:I, type:U){
    return (itype as unknown as U) == type
}

export function genericCast<T,V>(t:T){
    return t as unknown as V
}

export function isEmpty(val:ValidType){
    if (val === undefined || Number.isNaN(val) || val === null) {
        return true
    } else {
        let txt = ''+val
        txt = txt.trim()
        return txt.length == 0
    }
}
const debounceMap = {}
export function debounce(fn:Function,id:string,delay:number){
    clearTimeout(debounceMap[id])
    debounceMap[id] = setTimeout(fn,delay)
}

export function stripSkip(obj:any){
    if (Array.isArray(obj)) {
        return obj.map((subobj)=>stripSkip(subobj))
    } else if (isObject(obj)) {
        const res = Object.assign({}, obj)
        for (const [key, val] of Object.entries(res)) {
            if ((!val && val !==0) || val == 'skip'||val=='全部'||key == 'externalUpdate') {
                delete res[key]
            } else if (val == 'emptystring') {
                res[key] = ''
            } else if (val == 'zero') {
                res[key] = 0
            }
        }
        return res
    } else {
        return obj
    }
}

export function sleep(milis:number) {
    return new Promise((resolve,reject)=>{
        setTimeout(resolve,milis)
    })
}
export function getNowTimeString(){
    return (new Date()).toString()
}
export function getNowDateString(){
    return (new Date()).toDateString()
}
export const geoDistance = (lat1_:number|string,lon1_:number|string,lat2_:number|string,lon2_:number|string) => {
    let lat1 = cast2number(lat1_),lng1 = cast2number(lon1_),lat2 = cast2number(lat2_),lng2 = cast2number(lon2_)
    lat1 = lat1  * Math.PI / 180;
    lat2 = lat2  * Math.PI / 180;
    lng1 = lng1  * Math.PI / 180;
    lng2 = lng2  * Math.PI / 180;
    const EARTH_R = 6371393
    const cos = Math.cos(lat2) * Math.cos(lat1) * Math.cos(lng2 -lng1) + Math.sin(lat1) * Math.sin(lat2);
    const dis = EARTH_R * Math.acos(cos);
    console.log([lat1,lng1,lat2,lng2,dis].join('-'))
    return dis
}
export const cast2number = (pl:number|string) => {
    if (typeof pl === "number") {
        return pl
    } else if (typeof pl === "string") {
        return Number.parseFloat(pl)
    } else {
        return pl
    }
}
class mPromise {
    state = 'pending'
    value = null
    callbacks:any[] = []
    constructor(fn) {
        fn(this._resolve.bind(this))
    }
    then(onFulfilled){
        return new Promise(resolve=>{
            this._handle({onFulfilled,resolve})
        })
    }
    _resolve(value){
        this.value = value
        this.state = 'fulfilled'
        this.callbacks.forEach((cb)=>{
            this._handle(cb)
        })
    }
    _handle(cb){
        if (this.state === 'pending') {
            this.callbacks.push(cb)
        } else {
            let ret = this.value
            if (cb.onFulfilled) {
                ret = cb.onFulfilled(ret)
            }
            cb.resolve(ret)
        }
    }
}
type Location = {
    id: string,
    label: string,
    value: string,
    children: Location[]
}
type DropItem = {
    value: any,
    text: string,
}
//const mappingLocation = (currentLocation:Location, result:Record<any, DropItem[]>, parentValue?:any) => {
let mappingLocation1 = (currentLocation, result, parentValue) => {
    const value = currentLocation.value
    if (parentValue) {
        if (!result[parentValue]) {
            result[parentValue] = [{
                value:'skip',
                text: '全部',
            }]
        }
        const item = {
            value,
            text: currentLocation.label,
        }
        result[parentValue].push(item)
    }
    for (const child of currentLocation.children) {
        mappingLocation1(child, result, value)
    }
}
let mappingVToL = (currentLocation, result) => {
    const value = currentLocation.value
    const label = currentLocation.label
    result[value] = label
    for (const child of currentLocation.children) {
        mappingVToL(child, result)
    }
}
export function isPhoneNumber(phoneNumber: string): boolean {
    const pattern = /^(\+?86)?1[3-9]\d{9}$/;
    return pattern.test(phoneNumber);
}
export function formatDate(date: Date): string {
    let day = String(date.getDate());
    let month = String(date.getMonth() + 1); // Months are 0-based in JS
    const year = String(date.getFullYear());

    // Pad single digit month and day with leading zeros
    day = day.length < 2 ? '0' + day : day;
    month = month.length < 2 ? '0' + month : month;

    return `${year}-${month}-${day}`;
}

export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
    if (!obj) return {} as Pick<T, K>;
    let newObj = {} as Pick<T, K>;
    keys.forEach(key => {
        //@ts-ignore
        if (key in obj) newObj[key] = obj[key];
    });
    return newObj;
}

export function urlI18nProcess(url:string){
    const extra = window as unknown as ExtraRequestContext
    if (extra.param && extra.param!.loc) {
        if (extra.param!.loc == 'en') {
            url = url + '-en'
        }
    }
    return url
}
export function prepareImageUrl(url: string){
    if (url && url.startsWith("http")) {
        return url
    } else if(url) {
        return `https://www.charmwin.net/Uploads/${url}`
    } else {
        return url
    }
}
export function processImageUrlVersion(version:number|string|undefined){
    if (version) {
        return `?v=${version}`
    } else {
        return ''
    }
}

// sort object by key
export function sortObject(obj:Record<string, any>){
    const keys = Object.keys(obj).sort()
    let newObj = {}
    keys.forEach(key=>{
        newObj[key] = obj[key]
    })
    return newObj
}
// object id, md5 hash of json string of object
export function objectId(obj:Record<string, any>){
    return sha256(JSON.stringify(sortObject(obj)))
}

// convert any to int
export function toInt(val:any){
    if (typeof val === 'number') {
        return val
    } else if (typeof val === 'string') {
        return parseInt(val)
    } else {
        return 0
    }
}

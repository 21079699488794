import {request2, stripSkip} from "./util";

const authMiddleware: RequestMiddleWare<{status:number, message:string}> = async (req, next) => {
    if (typeof window === 'undefined') {
        req.url = process.env.REACT_APP_URL_BASE + req.url
    }
    let wd = window as any as {$token:string}
    if (!req.headers) req.headers = {}
    req.headers['Access-Token'] = wd.$token
    const resp = await next!(req)
    if (resp.parsedPayload) {
        let respObj = resp.parsedPayload
        if (respObj.status != 0) {
            console.log(respObj.message)
        }
    } else {
        resp.parsedPayload = {status:-1,message:'网络开了小差'}
    }
    return resp
}

export async function postBasic(url='',param={},data:any,header={}) {
    const extra = window as unknown as ExtraRequestContext
    if (extra.param) {
        param = Object.assign(param, extra.param)
    }
    const processedData = stripSkip(data)
    const resp = await request2({url, param, data:processedData, method:'POST'},[authMiddleware])
    return resp.parsedPayload
}

export async function getBasicRaw(url='',param={}) {
    const extra = window as unknown as ExtraRequestContext
    if (extra.param) {
        param = Object.assign(param, extra.param)
    }
    const resp = await request2({url, param, method:'GET'},[authMiddleware])
    return resp.parsedPayload
}

// post with file upload
export async function postFile2(url='',param={},file:File|Blob,header={}) {
    const formData = new FormData()
    formData.append('file', file)
    const resp = await request2({url, param, body:file, method:'POST',headers:{'Content-Type': 'multipart/form-data'}},[authMiddleware])
    return resp.parsedPayload
}
// postFile
export async function postFile(url='',param={},file:File|Blob,header={}) {

    // Create a FormData instance
    const formData = new FormData();

    // Append the file to the form with the key "file"
    formData.append('file', file);

    try {
        if (typeof window === 'undefined') {
            url = process.env.REACT_APP_URL_BASE + url
        }
        let urlAssembled = url
        if (param) {
            urlAssembled = urlAssembled + '?' + Object.entries(param).map(([key,val]) => key+'='+encodeURIComponent(''+val)).join('&')
        }
        let wd = window as any as {$token:string}
        const header = {
            'Access-Token': wd.$token
        }

        const response = await fetch(urlAssembled, {
            method: 'POST',
            headers: header,
            body: formData, // Send the FormData
        });

        // Check if the response is successful
        if (!response.ok) {
            throw new Error('Server responded with an error.');
        }

        // Assuming server responds with JSON that has a field "url" containing the new image URL
        const data = await response.json();
        return data;  // Return the new image URL
    } catch (error) {
        console.error('Upload Error:', error);
        throw error;  // Rethrow the error to be caught in the customUploadFunction
    }
}
